export const getSubscribersMixins = {
    methods: {
        getSubscriptions() {
            return this.$useJwt.subscription();
        },

        async getCurrentPlan() {
            const {data} = await this.getSubscriptions();
            return data.data.map(element => element.current_package.id);
        },

        async getPaymentPlan() {
            const {data} = await this.getSubscriptions();

            return data.data.map(
                element => element.payment_plan.payment_plan_name
            );
        },

        async getUuid() {
            const {data} = await this.getSubscriptions();
            return data.data[0].uuid;
        },
    },
};
